import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function ArrowUp(props) {
  return __jsx("svg", _extends({
    width: 16,
    height: 9,
    viewBox: "0 0 16 9"
  }, props), __jsx("path", {
    d: "M1.037 9L8 2.065 14.964 9 16 7.968 8 0 0 7.968z",
    fillRule: "evenodd"
  }));
}
export default ArrowUp;