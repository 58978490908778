import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "classNameAnchor", "classNameHeading", "classNamePara", "ariaLabel", "headingText", "HeadingType", "paraText", "paraHeadingClass", "address", "displayDirections", "headerOverride", "city", "state", "zip", "cta", "peekingImageAttrs", "dataAnalytics", "googleMyBusinessCID"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './AddressCard.style';
import Address from '../../atoms/Address';
import Heading from '../../atoms/Heading';
import Para from '../../atoms/Para';
import Image from '../../atoms/Image';
import Anchor from '../../atoms/Anchor';
import { getAddressURL, getCompleteAddress } from '../../../utils/utilityFunction';
import { linkTargetWindow } from '../../../constants/globalConstants';
// eslint-disable-next-line no-unused-vars
import { useYextFetch } from '../../../hooks/useYextFetch';
var AddressCard = function AddressCard(_ref) {
  var className = _ref.className,
    classNameAnchor = _ref.classNameAnchor,
    classNameHeading = _ref.classNameHeading,
    classNamePara = _ref.classNamePara,
    ariaLabel = _ref.ariaLabel,
    headingText = _ref.headingText,
    HeadingType = _ref.HeadingType,
    paraText = _ref.paraText,
    paraHeadingClass = _ref.paraHeadingClass,
    address = _ref.address,
    displayDirections = _ref.displayDirections,
    headerOverride = _ref.headerOverride,
    city = _ref.city,
    state = _ref.state,
    zip = _ref.zip,
    cta = _ref.cta,
    peekingImageAttrs = _ref.peekingImageAttrs,
    dataAnalytics = _ref.dataAnalytics,
    googleMyBusinessCID = _ref.googleMyBusinessCID,
    others = _objectWithoutProperties(_ref, _excluded);
  var addressString = address && getCompleteAddress(address.line1, address.line2, address.city, address.region, address.postalCode);
  var formatDirectionsAddress = function formatDirectionsAddress(addressStr) {
    return addressStr ? "".concat(addressStr.line1 ? addressStr.line1.split(' ').join('+') : '', "+").concat(addressStr.city, "+").concat(addressStr.region, "+").concat(addressStr.postalCode || zip) : '';
  };
  var directionsUrl = address && "https://www.google.com/maps/dir/?daddr=".concat(formatDirectionsAddress(address));
  var addressStringURL = getAddressURL(addressString);
  var GMBUrl = googleMyBusinessCID && "https://maps.google.com/maps?cid=".concat(googleMyBusinessCID);
  var peekingSource = peekingImageAttrs.peekingSource,
    peekingMobileSource = peekingImageAttrs.peekingMobileSource,
    peekingImageAltText = peekingImageAttrs.peekingImageAltText,
    peekingImgTitle = peekingImageAttrs.peekingImgTitle,
    peekingImageSrcSetting = peekingImageAttrs.peekingImageSrcSetting,
    imageOptimisationType = peekingImageAttrs.imageOptimisationType,
    peekingImageClassName = peekingImageAttrs.peekingImageClassName;
  return __jsx("div", {
    className: "nva-address-card ".concat(className)
  }, peekingSource && __jsx(Image, {
    src: peekingSource,
    alt: peekingImageAltText,
    title: peekingImgTitle || peekingImageAltText,
    className: "nva-address-card__image ".concat(peekingImageClassName),
    mobileImageUrl: peekingMobileSource,
    imageOptimisationType: imageOptimisationType,
    srcSetSettingImg: peekingImageSrcSetting
  }), __jsx(Anchor, _extends({
    className: "nva-address-card__anchor",
    href: GMBUrl || addressStringURL,
    to: GMBUrl || addressStringURL,
    title: addressString,
    ctaBehavior: linkTargetWindow.newWindow,
    "data-analytics-type": dataAnalytics && dataAnalytics.type ? dataAnalytics.type : '',
    "data-analytics-value": dataAnalytics && dataAnalytics.value ? dataAnalytics.value : '',
    "data-analytics-variation": dataAnalytics && dataAnalytics.variation ? dataAnalytics.variation : ''
  }, others), headingText && __jsx(Heading, {
    HeadingType: HeadingType,
    className: "nva-address-card__heading ".concat(classNameHeading)
  }, headerOverride || headingText), paraText && __jsx(Para, {
    className: "nva-address-card__para ".concat(paraHeadingClass)
  }, paraText), address && __jsx(Address, {
    className: "nva-address-card__address ".concat(classNamePara),
    addressLine1: address.line1,
    addressLine2: address.line2,
    city: address.city || city,
    state: address.region || state,
    postalCode: address.postalCode || zip
  })), displayDirections ? __jsx("div", {
    className: "directions-container"
  }, __jsx(Anchor, {
    className: "nva-address-card__directions",
    title: "Directions",
    ctaBehavior: linkTargetWindow.newWindow,
    href: directionsUrl,
    to: directionsUrl
  }, __jsx("picture", null, "Directions", __jsx("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    height: "48px",
    viewBox: "0 0 24 24",
    width: "48px"
  }, __jsx("path", {
    d: "M0 0h24v24H0V0z",
    fill: "none"
  }), __jsx("path", {
    d: "M22.43 10.59l-9.01-9.01c-.75-.75-2.07-.76-2.83 0l-9 9c-.78.78-.78 2.04 0 2.82l9 9c.39.39.9.58 1.41.58.51 0 1.02-.19 1.41-.58l8.99-8.99c.79-.76.8-2.02.03-2.82zm-10.42 10.4l-9-9 9-9 9 9-9 9zM8 11v4h2v-3h4v2.5l3.5-3.5L14 7.5V10H9c-.55 0-1 .45-1 1z"
  }))))) : false);
};
AddressCard.defaultProps = {
  className: '',
  ariaLabel: '',
  headingText: '',
  peekingImageAttrs: {}
};
export default styled(AddressCard).withConfig({
  componentId: "sc-ty7941-0"
})(["", ";"], styles);
export { AddressCard as AddressCardVanilla };