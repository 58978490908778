import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children", "className", "inheritedStyles", "value"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './ListItem.style';
var ListItem = function ListItem(_ref) {
  var children = _ref.children,
    className = _ref.className,
    inheritedStyles = _ref.inheritedStyles,
    value = _ref.value,
    others = _objectWithoutProperties(_ref, _excluded);
  return __jsx("li", _extends({
    className: "nva-list-item ".concat(className)
  }, others), children);
};
ListItem.defaultProps = {
  inheritedStyles: ''
};
export default styled(ListItem).withConfig({
  componentId: "sc-1dimaqi-0"
})(["", ";"], styles);
export { ListItem as ListItemVanilla };