import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function Phone(props) {
  return __jsx("svg", _extends({
    viewBox: "0 0 15.646 15.646"
  }, props), __jsx("path", {
    d: "M6.621 9.024c1.549 1.548 3.342 3.029 4.05 2.32 1.016-1.014 1.641-1.9 3.878-.1s.518 3-.465 3.98c-1.134 1.134-5.363.06-9.542-4.119S-.712 2.696.423 1.562c.983-.983 2.182-2.7 3.98-.464s.915 2.862-.1 3.877c-.708.708.772 2.5 2.319 4.049",
    fill: "#6e96ab",
    fillRule: "evenodd"
  }));
}
export default Phone;