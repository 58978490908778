import _slicedToArray from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _objectWithoutProperties from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var _excluded = ["className", "classNameHeading", "ariaLabel", "headingText", "mainHoursText", "extraHoursText", "contactCardCta", "contactCardLink", "contactCardHyperlinkType", "contactCardLinkCopy", "contactCardBehavior", "contactCardSysId", "perDayHoursDetailArray", "extendedHoursDetailArray", "extendedHours", "nameOfDay", "hoursTextLine", "extendedHoursTextLine", "dataAnalytics", "paraText", "classNameParaHeading", "isContactPage", "holidayHours", "openHoursMessage"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './HoursOfOperation.style';
import uuidv1 from 'uuid/v1';
import Heading from '../../atoms/Heading';
import Anchor from '../../atoms/Anchor';
import Tabs from '../../molecules/Tabs';
import Para from '../../atoms/Para';
import List from '../../molecules/List';
import ListItem from '../../molecules/List/ListItem';
import { timeConvertor } from '../../../utils/utilityFunction';
/** Function to create tab content
 * parameters : hours  - working hours fetched from Yext
 * returns -
 *  tabTitle, - tab title
 * 	tabType - type to denotes the content of different tabs
 *  others - extended hours array
 */
var createTabContent = function createTabContent(tabTitle, tabType, _ref) {
  var others = _extends({}, _ref);
  var content = '',
    tabClassName = '';
  if (tabType === 'operationalHours') {
    var hoursDetailArray = others.hoursDetailArray,
      nameOfDay = others.nameOfDay,
      className = others.className,
      paraClassName = others.paraClassName,
      hoursTextLine = others.hoursTextLine;
    content = __jsx(React.Fragment, null, __jsx(List, {
      className: "nva-hours-of-operation__panel-list panel-list"
    }, hoursDetailArray && hoursDetailArray.map(function (perDayhours, index) {
      var hours = perDayhours.split('-');
      var openHours = hours && hours[0];
      var closedHours = hours && hours[1];
      var openHoursSecond = hours && hours[2] && hours[2]; //in case of split open hours in a day NVA-104 - https://www.yext.com/s/2097750/entity/edit?entityIds=11668750
      var closedHoursSecond = hours && hours[3] && hours[3];
      return __jsx(ListItem, {
        key: uuidv1(),
        className: "nva-hours-of-operation__panel-list-item"
      }, __jsx(Para, {
        className: "nva-hours-of-operation__day ".concat(paraClassName)
      }, nameOfDay[index]), perDayhours.toLowerCase() === 'closed' ? __jsx(Para, null, perDayhours) : __jsx("div", null, __jsx(Para, null, __jsx("span", {
        className: "nva-hours-of-operation__open-hours",
        content: timeConvertor(openHours)
      }, openHours), closedHours && __jsx("span", {
        className: "nva-hours-of-operation__closed-hours",
        content: timeConvertor(closedHours)
      }, '- ', " ", closedHours)), __jsx(Para, null, openHoursSecond && __jsx("span", {
        className: "nva-hours-of-operation__open-hours-second",
        content: timeConvertor(openHoursSecond)
      }, openHoursSecond), closedHoursSecond && __jsx("span", {
        className: "nva-hours-of-operation__closed-hours-second",
        content: timeConvertor(closedHoursSecond)
      }, '- ', " ", closedHoursSecond))));
    })), __jsx(List, {
      className: "nva-hours-of-operation__panel-list panel-list"
    }, __jsx(ListItem, null, __jsx(Para, {
      className: "nva-hours-of-operation__message message"
    }, hoursTextLine))));
    tabClassName = className;
  }
  return {
    tabTitle: tabTitle,
    panelContent: content,
    tabClassName: tabClassName
  };
};
var HoursOfOperation = function HoursOfOperation(_ref2) {
  var className = _ref2.className,
    classNameHeading = _ref2.classNameHeading,
    ariaLabel = _ref2.ariaLabel,
    headingText = _ref2.headingText,
    mainHoursText = _ref2.mainHoursText,
    extraHoursText = _ref2.extraHoursText,
    contactCardCta = _ref2.contactCardCta,
    contactCardLink = _ref2.contactCardLink,
    contactCardHyperlinkType = _ref2.contactCardHyperlinkType,
    contactCardLinkCopy = _ref2.contactCardLinkCopy,
    contactCardBehavior = _ref2.contactCardBehavior,
    contactCardSysId = _ref2.contactCardSysId,
    perDayHoursDetailArray = _ref2.perDayHoursDetailArray,
    extendedHoursDetailArray = _ref2.extendedHoursDetailArray,
    extendedHours = _ref2.extendedHours,
    nameOfDay = _ref2.nameOfDay,
    hoursTextLine = _ref2.hoursTextLine,
    extendedHoursTextLine = _ref2.extendedHoursTextLine,
    dataAnalytics = _ref2.dataAnalytics,
    paraText = _ref2.paraText,
    classNameParaHeading = _ref2.classNameParaHeading,
    isContactPage = _ref2.isContactPage,
    holidayHours = _ref2.holidayHours,
    openHoursMessage = _ref2.openHoursMessage,
    others = _objectWithoutProperties(_ref2, _excluded);
  var tabsContent = [];
  perDayHoursDetailArray.length !== 0 && tabsContent.push(createTabContent(mainHoursText, 'operationalHours', {
    hoursDetailArray: perDayHoursDetailArray,
    //TODO: Need to map and split same as the one the goes to the footer
    nameOfDay: nameOfDay,
    className: 'nva-hours-of-operation__hour-details-tab ' + (extendedHours ? 'bar' : '') + ' hour-details-tab',
    paraClassName: 'left',
    hoursTextLine: hoursTextLine
  }));
  if (extendedHours) {
    tabsContent.push(createTabContent(extraHoursText, 'operationalHours', {
      hoursDetailArray: extendedHoursDetailArray,
      nameOfDay: nameOfDay,
      className: 'nva-hours-of-operation__hour-details-tab hour-details-tab',
      paraClassName: 'left',
      hoursTextLine: extendedHoursTextLine
    }));
  }
  var tabProps = {
    className: 'nva-hours-of-operation__hours-detail hours-detail',
    tabsContent: [].concat(tabsContent),
    tabsId: 'default-tab',
    initialSelectedIndex: 0,
    selectedIndex: extendedHours ? 1 : 0
  };
  var converDatetoUS = function converDatetoUS(entryDate) {
    var _entryDate$split = entryDate.split('-'),
      _entryDate$split2 = _slicedToArray(_entryDate$split, 3),
      year = _entryDate$split2[0],
      month = _entryDate$split2[1],
      day = _entryDate$split2[2];
    return month + '/' + day + '/' + year.slice(2);
  };
  var getAMPM = function getAMPM(militaryTime) {
    var _militaryTime$split = militaryTime.split(':'),
      _militaryTime$split2 = _slicedToArray(_militaryTime$split, 3),
      hours = _militaryTime$split2[0],
      minutes = _militaryTime$split2[1],
      seconds = _militaryTime$split2[2];
    var hoursNum = parseInt(hours, 10);
    var ampm = 'am';
    if (hoursNum > 12) {
      hoursNum -= 12;
      ampm = 'pm';
    }
    if (hoursNum == 12) {
      hoursNum = 12;
      ampm = 'pm';
    }
    if (hoursNum == 0) {
      hoursNum = 12;
      ampm = 'am';
    }
    var hoursString = hoursNum.toString();
    return "".concat(hoursString, ":").concat(minutes).concat(ampm);
  };
  return __jsx("div", {
    className: "nva-hours-of-operation ".concat(className)
  }, perDayHoursDetailArray.length !== 0 && headingText && __jsx(Heading, {
    HeadingType: "h2",
    className: "nva-hours-of-operation__heading hoursText heading_three ".concat(classNameHeading)
  }, headingText), perDayHoursDetailArray.length !== 0 && paraText && __jsx(Heading, {
    HeadingType: "h3",
    "aria-label": paraText,
    className: "nva-hours-of-operation__hours-text hoursText ".concat(classNameParaHeading)
  }, paraText), openHoursMessage && __jsx("p", {
    className: "nva-hours-of-operation__open-hours-msg"
  }, openHoursMessage), __jsx(Tabs, tabProps), holidayHours && __jsx(Heading, {
    HeadingType: "h3",
    "aria-label": "Holiday Hours",
    className: "nva-hours-of-operation__hours-text hoursText ".concat(classNameHeading)
  }, "Holiday Hours"), holidayHours && __jsx(List, {
    className: "nva-hours-of-operation__panel-list panel-list"
  }, holidayHours.map(function (entry) {
    return __jsx(ListItem, {
      key: uuidv1(),
      className: "nva-hours-of-operation__panel-list-item"
    }, __jsx(Para, {
      className: "left"
    }, converDatetoUS(entry.date)), entry.isClosed ? __jsx(Para, null, "Closed") : __jsx("div", null, entry.openIntervals.map(function (interval) {
      if (interval.start == '00:00' && interval.end == '23:59') {
        return __jsx(Para, null, " Open 24 Hours");
      } else return __jsx(Para, null, __jsx("span", {
        className: "nva-hours-of-operation__open-hours",
        content: getAMPM(interval.start)
      }, getAMPM(interval.start)), __jsx("span", {
        className: "nva-hours-of-operation__closed-hours",
        content: getAMPM(interval.end)
      }, '- ', " ", getAMPM(interval.end)));
    })));
  })), contactCardLinkCopy && !isContactPage && __jsx(Anchor, {
    "data-entry-id": contactCardSysId,
    title: contactCardLinkCopy,
    primary: "true",
    styleType: "primary-anchor",
    type: contactCardCta === null || contactCardCta === void 0 ? void 0 : contactCardCta.type,
    className: "nva-hours-of-operation__location-button location-button ",
    hyperlinkType: contactCardHyperlinkType,
    ctaBehavior: contactCardBehavior,
    href: contactCardLink,
    to: contactCardLink,
    "data-analytics-type": dataAnalytics && dataAnalytics.type ? dataAnalytics.type : '',
    "data-analytics-value": dataAnalytics && dataAnalytics.value ? dataAnalytics.value : '',
    "data-analytics-variation": dataAnalytics && dataAnalytics.variation ? dataAnalytics.variation : ''
  }, contactCardLinkCopy));
};
HoursOfOperation.defaultProps = {
  className: '',
  ariaLabel: '',
  headingText: '',
  extendedHours: false,
  isContactPage: false
};
export default styled(HoursOfOperation).withConfig({
  componentId: "sc-yfqjgu-0"
})(["", ";"], styles);